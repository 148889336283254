import React from "react";
import { TeamMemberCard, Text } from "components";
import { teamMembersData } from "config";

const TeamMembers = () => {
  return (
    <section className="bg-secondary-100">
      <div className="pt-[183px]">
        <Text
          variant="h3"
          value="Our Leadership Team"
          color="primary-900"
          className="text-center"
        />
        <div className="">
          {teamMembersData.map(member => (
            <div className="even:bg-neutral-900">
              <TeamMemberCard {...member} />
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export { TeamMembers };
