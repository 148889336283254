import React from "react";
import { StaticImage } from "gatsby-plugin-image";
import { Br } from "components";

const teamMembersData = [
  {
    name: "Isaac Mate",
    position: "Chief Executive Officer",
    image: (
      <StaticImage
        src="../assets/images/pngs/about/MR ISAACweb.png"
        alt="Isaac team member"
      />
    ),
    bio: (
      <>
        <p>
          Isaac is a prolific HSE and risk management expert with a proven
          record in business operations. He is passionate about imparting
          knowledge on operations risk management, incident, emergency and
          crisis management, workplace sustainability strategy, and many other
          fields in improving the healthiness of a workplace.{" "}
        </p>
        <p>
          Isaac holds a Bachelor’s Degree in Mining Engineering (UMaT, Ghana),
          owns a Master's degree in Safety & Environment from the University of
          Magdeburg in Germany and a PhD in Safety Risk Management from
          Queensland, Australia (in view). <Br /> As a certified Business
          Manager (Bradford, UK), and Industrial Hygiene Executive (Harvard,
          USA), Isaac’s professionalism proofs itself..
        </p>

        <p>
          He currently functions as the Group HSE and Risk Director at CEC
          Africa Investments Limited - Nigeria, Zambia, Namibia and Sierra
          Leone. His drive to foster growth in organizations and implement
          excellence earned him opportunities and positions such as working as
          the Senior Partner at the HSE Worldwide Limited in Ghana and vice
          president of health, safety, and Environment (HSE) at AngloGold
          Ashanti, Risk Management Specialist at BHP in Guinea and many more.
        </p>
        <p>
          Isaac has bilingual (English and French), multicultural and remote
          site experience. He is a proficient problem solver possessing advanced
          technical and practical know-how in identifying, assessing, and
          managing operational and strategic HSES risks across multiple
          industries.
        </p>
      </>
    ),
    quote: "'Why be ordinary when you can be phenomenal'",
  },

  {
    name: "Afua Ohenewa Owusu Antwi",
    position: "Associate Consultant",
    image: (
      <StaticImage
        src="../assets/images/jpegs/about/Afua.jpg"
        alt="Afua team member"
      />
    ),
    bio: (
      <>
        <p>
          Afua is a specialist in coordinating activities, events, and seminars
          on health, safety, environment, and risk management. She derives joy
          in using research to proffer customer-centric solutions to issues.
          She’s keen on helping workplaces and companies achieve their goals to
          keep the workplace healthy.
        </p>

        <p>
          She has accrued 3 years experience in Academia, Research Institutions
          & Standard Laboratories and currently works as the Associate
          Consultant for HSE Worldwide, Ghana.
        </p>
        <p>
          Afua holds a Bachelor of Science degree in Biochemistry, Cell and
          Molecular Biology (Legon, Ghana) and has worked at Thrivus Institute
          for Biomedical Science and Technology, Ghana and also the West African
          Regional Rotavirus Reference Laboratory, Noguchi Memorial Institute
          for Medical Research in Ghana.
        </p>
      </>
    ),

    quote:
      "‘You are never too old to set another goal or to dream a new dream’",
  },
  {
    name: "Johanna Adwoa Entsie",
    position: "Sustainability Leader",
    image: (
      <StaticImage
        src="../assets/images/jpegs/about/Johanna.jpg"
        alt="Johanna team member"
      />
    ),
    bio: (
      <>
        <p>
          With 20 years of experience in Strategy, Risk Assessment and Incident
          Analysis, Johanna has greatly impacted organizations through teaching,
          and mentoring. She has worked in the Oil and Gas industry as well as
          the Manufacturing Food & Beverage industry, and currently manages the
          Regional Health, Safety and Environment team at Nestle Central and
          West Africa (CWAR) offices.
        </p>
        <p>
          Johanna holds a Bachelor's degree in Chemical Engineering at Kwame
          Nkrumah University of Science and Technology, an MPhil in
          Environmental Science at the University of Ghana, a Masters of
          Business Administration degree in Finance at the University of
          Leicester in the United Kingdom and a certified Health, Safety &
          Environment fellow (NEBOSH IGC) .
        </p>
      </>
    ),
    quote:
      "‘Work as if it all depends on you, and pray as if it all depends on God’",
  },
];

export { teamMembersData };
