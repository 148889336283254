import React from "react";
import { PageHeaderTwo } from "components";
import { StaticImage } from "gatsby-plugin-image";

const AboutUsPageHeader = () => {
  return (
    <PageHeaderTwo
      title="Providing a Safe and healthy working Environment for Professionals"
      description="By Enabling Professionals to Meet the World’s Concerns on Social and Governance Sustainability, We Are Redefining the Health, Safety, Environmental and Risk Management System in the Workplace."
      color="primary-900"
      button={{ text: "Get Started", to: "/" }}
      image={
        <StaticImage
          src="../../../assets/images/pngs/about/Hero.png"
          width={2000}
          layout="fullWidth"
        />
      }
    />
  );
};

export { AboutUsPageHeader };
