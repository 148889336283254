import React from "react";
import ctl from "@netlify/classnames-template-literals";
import Arrow from "svgs/timeline-arrow.svg";
import { Text } from "components";

const TimelineSteps = ({ year, description, isLast }) => {
  return (
    <div className={timelineStepWrapStyle}>
      <div className={arrowWrapStyle}>
        <Arrow
          className={`${
            !isLast && "xl:scale-[2] lg:scale-[2.8]"
          } ${arrowStyle}`}
        />
        <div className={`${lineStyle} ${isLast && "hidden"} `}></div>
      </div>

      <div className="mb-12 lg:mb-0">
        <Text
          variant="h5"
          value={year}
          color="secondary-700"
          className={yearStyle}
        />
        <Text variant="p16" value={description} className="max-w-[245px]" />
      </div>
    </div>
  );
};

const timelineStepWrapStyle = ctl(`
  flex 
  lg:block
  gap-8
`);
const arrowWrapStyle = ctl(`
  flex
  flex-col lg:flex-row
  items-center
  lg:gap-[22px]
  gap-[9px]
`);
const arrowStyle = ctl(`
 rotate-90
 lg:rotate-0
`);
const lineStyle = ctl(`
  lg:w-[500px]
  h-[100%] 
  lg:h-[0.8px]
  w-[.8px]
  bg-black
  mb-[9px] lg:mb-0
`);
const yearStyle = ctl(`
  lg:mt-[35px]
  lg:mb-3
  mb-[15px]
`);

export { TimelineSteps };
