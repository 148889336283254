import React from "react";
import { Br } from "components";

const growthData = [
  {
    number: "49",
    text: (
      <>
        Trained professionals <Br /> in Ghana
      </>
    ),
  },
  {
    number: "300",
    text: "SHERM participants",
  },
  {
    number: "14",
    text: "Countries in attendance ",
  },
  {
    number: "50+",
    text: (
      <>
        Members in mentoring <Br /> programmes
      </>
    ),
  },
];

export { growthData };
