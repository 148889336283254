import React from "react";
import ctl from "@netlify/classnames-template-literals";
import { Text } from "components";
import ReliabilityIcon from "svgs/reliability .svg";
import TrustIcon from "svgs/trust.svg";
import StrategicIcon from "svgs/strategic.svg";
import YouthIcon from "svgs/youthfulness.svg";
import ValueIcon from "svgs/value.svg";
import ExcellenceIcon from "svgs/excellence.svg";
import LegoIcon from "svgs/about-us-lego.svg";

const DesktopCoreValues = () => {
  return (
    <div className={sectionWrapStyle}>
      <div className="-mt-20">
        <IconAndText
          left
          icon={<StrategicIcon />}
          text="Strategic"
          description="We are deliberate in what we do each day in order to
          get to that future. This future starts with our
          minds, our activities and our actions."
        />

        <IconAndText
          left
          icon={<ReliabilityIcon />}
          text="Resilient"
          extraClass="mt-16"
          description="We are deliberate in what we do each day in order to
          get to that future. This future starts with our
          minds, our activities and our actions."
        />

        <IconAndText
          left
          icon={<ValueIcon />}
          text="Value-adding"
          extraClass=""
          description="Our relationship with our clients is value adding,
          ensuring we help transform mindsets and bring
          meaningful progress to all parties."
        />
      </div>

      <LegoIcon className="mt-20 scale-[1.2] " />

      <div className="mt-8">
        <IconAndText
          icon={<TrustIcon />}
          text="Trustworthy"
          description="We are committed to an honest and open relatioship.
          We are dependable, reliable, we will do what we say
          and say what we can do."
        />

        <IconAndText
          icon={<YouthIcon />}
          text="Youthful"
          extraClass="mt-32"
          description="We bring positive energy into whatever we do. The zest is infectious!"
        />

        <IconAndText
          icon={<ExcellenceIcon />}
          text="Excellence"
          extraClass="mt-24"
          description="We strive to be outstanding in ourwork and in our relationships with our valuable clients."
        />
      </div>
    </div>
  );
};

const IconAndText = ({ icon, text, left, extraClass, description }) => (
  <div
    className={`${valueWrapStyle} ${
      left ? leftValueStyle : rightValueStyle
    } ${extraClass}`}
  >
    <span>{icon}</span>
    <Text value={text} variant="h5" color="alternative-700" />
    <Text value={description} variant="p16" color="neutral-900" />
  </div>
);

const sectionWrapStyle = ctl(`
  hidden 
  xl:flex 
  justify-center
  mt-48
`);
const valueWrapStyle = ctl(`
  flex 
  flex-col
  gap-3 
  max-w-[380px]
  2xl:max-w-[480px]
  2xl:mb-20 mb-12
`);
const leftValueStyle = ctl(`
  mr-7
  2xl:mr-10
  items-end
  text-right
`);
const rightValueStyle = ctl(`
  2xl:ml-3
`);

export { DesktopCoreValues };
