import React from "react";
import { Layout, OurMission } from "components";
import {
  AboutUsPageHeader,
  AboutCoreValues,
  TeamMembers,
  Timeline,
} from "templates/about-us";

const AboutUs = () => {
  return (
    <Layout title="About Us">
      <AboutUsPageHeader />
      <OurMission />
      <AboutCoreValues />
      <TeamMembers />
      <Timeline />
    </Layout>
  );
};

export default AboutUs;
