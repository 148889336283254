import React from "react";
import { Container, Text } from "components";
import ctl from "@netlify/classnames-template-literals";
import { TimelineSteps } from "./timeline-steps";

const Timeline = () => {
  return (
    <Container>
      <Text
        variant="h3"
        value="Our Growth in Stages"
        color="primary-900"
        className={headingStyle}
      />

      <TimelineItem />
    </Container>
  );
};

const TimelineItem = () => (
  <section className={sectionWrapperStyles}>
    {timelineData.map((item, index) => {
      const isLast = index == timelineData.length - 1;

      return (
        <TimelineSteps key={`timeline_${index}`} {...item} isLast={isLast} />
      );
    })}
  </section>
);

const timelineData = [
  {
    year: "2018",
    description:
      "Trained 49 Health, Safety and Environment sustainability Professionals in Ghana.",
  },
  {
    year: "2019/2020",
    description: "Educated over 300 Professionals from 14 Countries.",
  },
  {
    year: "2021",
    description:
      "Conducted the first fully virtual FREE training on SHERM FOUNDATION with 115 participants from Ghana, Nigeria, Sierra Leone, Guinea, Senegal, Kenya, Zambia, Namibia, Zimbabwe, Tanzania, South Africa, UAE, Indonesia, and India.",
  },
  {
    year: "2022",
    description:
      "SHERM CERTIFICATE COURSE – 18 participants from two countries (Ghana and Mali)",
  },
];

const headingStyle = ctl(`
  text-center 
  pt-[126px] 
  pb-[46px] 
  lg:pb-[71px]
  xl:-ml-28
  lg:-ml-10
`);
const sectionWrapperStyles = ctl(`
  pb-[126px]
  grid 
  lg:grid-cols-4
  grid-cols-1
  lg:gap-[22px]
`);

export { Timeline };
